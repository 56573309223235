<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="listQuery.school_id"
        placeholder="请选择学校"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
        @change="clearData"
      >
        <el-option
          v-for="item in schools"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-date-picker
        v-model="listQuery.school_year"
        type="year"
        class="filter-item"
        value-format="yyyy"
        placeholder="选择学年"
        @change="
          getCollegeList(), (listQuery.semester = null);
          (listQuery.college_id = null),
            (colleges = []),
            (listQuery.major_id = null),
            (majors = []),
            (listQuery.grade_id = null),
            (grades = []),
            (listQuery.class_id = null),
            (classes = []);
        "
      >
      </el-date-picker>
      <el-select
        v-model="listQuery.college_id"
        placeholder="请选择院系"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
        @change="
          getMajorList(), (listQuery.semester = null);
          (listQuery.major_id = null),
            (majors = []),
            (listQuery.grade_id = null),
            (grades = []),
            (listQuery.class_id = null),
            (classes = []);
        "
      >
        <el-option
          v-for="item in colleges"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.major_id"
        placeholder="请选择专业"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
        @change="
          listQuery.semester = null;
          (listQuery.grade_id = null),
            (grades = []),
            (listQuery.class_id = null),
            (classes = []);
        "
      >
        <el-option
          v-for="item in majors"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>

      <el-select
        v-model="listQuery.semester"
        placeholder="请选择学期"
        clearable
        style="width: 150px; margin-left: 10px"
        class="filter-item"
        @change="
          getGradeList(),
            (listQuery.grade_id = null),
            (grades = []),
            (listQuery.class_id = null),
            (classes = [])
        "
      >
        <el-option label="第一学期" :value="1" />
        <el-option label="第二学期" :value="2" />
      </el-select>
      <el-select
        v-model="listQuery.grade_id"
        placeholder="请选择年级"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
        @change="getClassList(), (listQuery.class_id = null), (classes = [])"
      >
        <el-option
          v-for="item in grades"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.class_id"
        placeholder="请选择班级"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
      >
        <el-option
          v-for="item in classes"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-button class="filter-item" type="primary" @click="getList">
        查找
      </el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      @sort-change="changeSort"
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center" sortable>
        <template slot-scope="scope">
          {{ scope.row.college ? scope.row.college.school.name : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="院系" min-width="110" align="center" sortable>
        <template slot-scope="scope">
          {{ scope.row.college ? scope.row.college.name : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="专业" min-width="110" align="center" sortable>
        <template slot-scope="scope"
          >{{ scope.row.major ? scope.row.major.name : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="年级" min-width="110" align="center">
        <template slot-scope="scope"
          >{{ scope.row.grade ? scope.row.grade.name : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="班级" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.name }} </template>
      </el-table-column>
      <el-table-column label="学年" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.grade
              ? scope.row.school_year + "~" + (scope.row.school_year - 0 + 1)
              : "-"
          }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.is_on">正常</el-tag>
          <el-tag type="danger" v-else>已下架</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handlerStudent(scope.row)"
          >
            学生管理
          </el-button>
          <!-- <el-button
            size="mini"
            type="primary"
            @click="handleCheckCurriculum(scope.row)"
          >
            查看课表
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: [],
      total: null,
      listLoading: false,
      colleges: [],
      grades: [],
      majors: [],
      schools: [],
      classes: [],
      school_year: null,
      listQuery: {
        page: 1,
        limit: 10,
        school_year: "",
        college_id: "",
        school_id: "",
        class_id: "",
        grade_id: "",
        major_id: "",
        school_name_sort: "",
        college_name_sort: "",
        major_name_sort: "",
        is_class_class: 1,
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      form: {
        id: "",
        college_id: "",
        major_id: "",
        grade_id: "",
        name: "",
        school_year: "",
      },
      rules: {
        school_year: [
          { required: true, message: "学年不能为空", trigger: "change" },
        ],
        grade_id: [
          { required: true, message: "年级不能为空", trigger: "change" },
        ],
        college_id: [
          { required: true, message: "院系不能为空", trigger: "change" },
        ],
        major_id: [
          { required: true, message: "专业不能为空", trigger: "change" },
        ],
        name: [{ required: true, message: "班级不能为空", trigger: "change" }],
      },
      btnLoading: false,
      grade_id: "",
    };
  },
  watch: {
    school_year(newV, oldV) {
      console.log(newV, oldV, "=========");
      this.form.school_year = newV;
    },
    grade_id(newV, oldV) {
      console.log(newV, oldV, "=========");
      this.form.grade_id = newV;
    },
  },
  created() {
    // this.getCollegeList();
    this.getSchoolList();
    this.getList();
  },
  methods: {
    //排序
    changeSort(e) {
      console.log(e);
      this.listQuery.school_name_sort = "";
      this.listQuery.college_name_sort = "";
      this.listQuery.major_name_sort = "";
      if (e.order == null) {
        this.getList();
        return;
      }
      switch (e.column.label) {
        case "学校":
          if (e.order == "ascending") {
            this.listQuery.school_name_sort = 0;
          } else {
            this.listQuery.school_name_sort = 1;
          }
          break;
        case "院系":
          if (e.order == "ascending") {
            this.listQuery.college_name_sort = 0;
          } else {
            this.listQuery.college_name_sort = 1;
          }
          break;
        case "专业":
          if (e.order == "ascending") {
            this.listQuery.major_name_sort = 0;
          } else {
            this.listQuery.major_name_sort = 1;
          }
          break;
        default:
          break;
      }
      this.getList();
    },
    handleCheckCurriculum(row) {
      this.$router.push(`/task/check_curriculum?class_id=${row.id}`);
    },
    clearData(key) {
      this.listQuery = {
        page: 1,
        limit: 999999,
        school_year: "",
        college_id: "",
        school_id: key,
        class_id: "",
        grade_id: "",
        major_id: "",
        school_name_sort: "",
        college_name_sort: "",
        major_name_sort: "",
        is_class_class: 1,
      };
      this.colleges = [];
      this.grades = [];
      this.majors = [];
      this.classes = [];
    },
    changeSelect(e) {
      console.log(11111111);
      console.log(e);
    },
    handlerStudent(row) {
      this.$router.push(
        `/task/class_student?colleage_id=${row.college_id}&id=${row.id}&grade_id=${row.grade_id}`
      );
    },
    getSchoolList() {
      request({
        url: "/api/teacherend/school/list",
        method: "get",
        params: {
          limit: 99999999999999,
        },
      }).then((response) => {
        this.schools = response.data.data;
        // this.school_list.push(response.data.data);
      });
    },
    //获取院系列表
    getCollegeList() {
      request({
        url: "/api/teacherend/school/collegeList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.colleges = response.data.data;
      });
    },
    //获取年级
    getGradeList(key) {
      console.log(key, "========");
      request({
        url: "/api/teacherend/school/gradeList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.grades = response.data.data;
        // this.$forceUpdate()//强制更新
      });
    },
    //获取专业
    getMajorList(key) {
      request({
        url: "/api/teacherend/school/majorList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.majors = response.data.data;
        if (this.form.major_id && key != 1) {
          this.form.major_id = null;
        }
      });
    },
    //获取班级
    getClassList() {
      this.classes = [];
      request({
        url: "/api/teacherend/school/classList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.classes = response.data.data;
        createLogger;
        // this.classes.push(response.data.data);
      });
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/teacherend/school/classList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        name: "",
      };
    },
    handleCreate() {
      this.resetForm();
      this.grade_id = "";
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    handleUpdate(row) {
      console.log(row);
      this.resetForm();
      this.form = Object.assign({}, row);
      this.school_year = row.grade.school_year;
      this.form.school_year = row.grade.school_year;
      this.grade_id = row.grade_id;
      // if (this.form.school_year) {
      this.getGradeList(1);
      // }
      this.getMajorList(1);
      // console.log();
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/teacherend/school/classStore",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该院系, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/teacherend/school/classUpDown",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    // 获取省
    getProvince() {
      return request({
        url: "/api/common/area/index",
        method: "get",
      }).then((res) => {
        this.provinces = res.data;
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
